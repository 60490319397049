import React from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import "./home.css";
import { Social } from "../social";
import coin from "./coin.png";

export function Home() {
  return (
    <>
      <Helmet>
        <title>Mythical Gladiator - Revolutionizing Gaming</title>
        <meta
          name="description"
          content="Welcome to MYG, a digital currency transforming gaming. Earn, trade, and connect in the future of gaming with Mythical Gladiator."
        />
        <meta
          name="keywords"
          content="Mythical Gladiator, MYG, gaming token, blockchain, NFT, crypto gaming"
        />
        <meta
          property="og:title"
          content="Mythical Gladiator - Revolutionizing Gaming"
        />
        <meta
          property="og:description"
          content="Explore MYG, the digital currency that brings gaming into the future. Join the community, earn rewards, and trade unique items."
        />
        <meta property="og:image" content={coin} />
        <meta property="og:url" content="https://yourdomain.com/" />
      </Helmet>

      <div className="container-fluid maindiv">
        <div className="homeflex">
          <h1 className="gladiator">Mythical Gladiator</h1>
          <h1 className="myg">
            MYG takes you into{" "}
            <span className="future">
              <br />
            </span>{" "}
            the future of gaming.
          </h1>
          <div className="home_left_right">
            <div className="homeleft">
              <div>
                <p>
                  Welcome to MYG, a digital currency that will transform the way
                  you play, earn, and connect. Whether you're a gamer looking to
                  maximize your rewards, MYG can help.
                  <br />
                  <br />
                  <span className="power">Unlock the Power of Gaming</span>
                  <br />
                  <br />
                  Earn while you play: With our play-to-earn environment, you
                  can convert every victory into real-world value. Purchase
                  In-Game Assets and Receive Payment: Buy unique items with MYG
                  and make money by trading and selling them in our ecosystem.
                  Join our Community: Connect with gamers who share your
                  passions. Collaborate, compete, and celebrate together.
                </p>
              </div>
            </div>
            <div className="homeright">
              <img src={coin} alt="MYG Coin" className="homecoin" />
            </div>
          </div>
          <div className="twobtn">
            <NavLink to="/presale" className="homesale">
              <p>Buy Pre-Sale</p>
            </NavLink>
            <NavLink to="/nft" className="homesale">
              <p>Buy NFT</p>
            </NavLink>
          </div>
        </div>
        <Social />
      </div>
    </>
  );
}
